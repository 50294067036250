
import { defineComponent } from 'vue'
import user from '@/utils/user'
import { welcomeTime, welcome } from '@/utils/util'
import { ElNotification } from 'element-plus'
export default defineComponent({
    name: 'Login',
    data () {
        return {
            phone: '',
            code: '',
            time: 0,
            isWechat: false
        }
    },
    methods: {
        async submit () {
            // 验证输入信息
            if (!/^1\d{10}$/.test(this.phone)) {
                this.$message.warning('请输入手机号码')
                return
            }
            if (!this.code) {
                this.$message.warning('请输入验证码')
                return
            }
            await user.login({
                mobile: this.phone,
                smsCode: this.code
            })
            this.$router.push({
                path: '/'
            })
            this.$message.success(welcomeTime() + '，' + user.userName)
            ElNotification({
                title: welcomeTime() + '，' + user.userName,
                message: welcome(),
                type: 'success'
            })
        },
        // 获取短信验证码
        async getSms () {
            // 对手机号码验证
            if (/^1\d{10}$/.test(this.phone)) {
                await user.getSms(this.phone)
                this.$message.success('短信发送成功，请注意查收！')
                // 设置倒计时
                this.setTime()
            } else {
                this.$message.warning('请输入手机号码')
            }
        },
        setTime () {
            this.time = 60
            const timer = setInterval(() => {
                this.time -= 1
                if (this.time === 0) {
                    clearInterval(timer)
                }
            }, 1000)
        }
    }
})
